/* global rp$, document, window */
import './make_logos.scss';

var THE_SCRIPT = function () {
	const $onReady = require('public/js/lib/on-ready');

	(function MakeLogosModule() {
		$onReady({
			rp$, window,
			label: 'make_logos',
			fn: function () {},
		});
	})();
};

try {
	// Because __CURRENT_SCRIPT__ is injected by plugin Client, we need this here to try and pass it up to the plugin code
	if (typeof __CURRENT_SCRIPT__ === 'undefined') {
		throw new Error('__CURRENT_SCRIPT__ is not defined');
	}
	__CURRENT_SCRIPT__ = THE_SCRIPT;
} catch (e) {
	THE_SCRIPT();
}
